import React from 'react';
import BizRegulation from '@elegantstack/gatsby-starter-flexiblog-agency/src/components/BizRegulation'
import {Layout} from "@layout/Layout/Layout";
import GMCLayout from "../layouts/gmc";
import Seo from '@widgets/Seo';

const Regulation = (props) => {
    return (
        <Layout {...props}>
            <Seo title='Business Formation' description='Start a business remotely' />
            {/* <Divider /> */}
            <BizRegulation/>
        </Layout>
    );
};

export default Regulation;